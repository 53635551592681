export function ParcelIcon(props: { className: string }) {
    return (
        <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M20 5.35772V5.35391C20 5.30868 19.9897 5.26561 19.9753 5.22419C19.972 5.21491 19.9689 5.20613 19.9649 5.19702C19.9472 5.15626 19.9241 5.11816 19.8936 5.08536C19.8897 5.08105 19.8845 5.07774 19.8802 5.0736C19.8635 5.0572 19.8469 5.04046 19.8272 5.02704C19.8106 5.01578 19.7923 5.00849 19.7745 4.99987C19.7697 4.99755 19.7667 4.99325 19.7619 4.99109L10.6577 1.0328C10.5568 0.989066 10.4428 0.989066 10.3419 1.0328L7.18348 2.40602C7.18348 2.40602 7.18332 2.40602 7.18332 2.40619L4.57274 3.54119C4.57241 3.54135 4.57208 3.54152 4.57174 3.54169L1.2379 4.99129C1.23309 4.99344 1.23011 4.99775 1.22531 5.00007C1.20741 5.00869 1.18919 5.01597 1.17246 5.02724C1.15291 5.04049 1.13634 5.05723 1.11961 5.07363C1.1153 5.07777 1.11033 5.08092 1.10636 5.08523C1.07588 5.11803 1.05285 5.15613 1.03512 5.19705C1.03115 5.20616 1.028 5.21478 1.02468 5.22422C1.01027 5.2658 1 5.30888 1 5.35394V5.35775V5.35824V16.0415C1 16.2046 1.0999 16.3508 1.25165 16.4102L10.3457 19.9689L10.3557 19.9727C10.4021 19.9909 10.4509 20 10.4998 20C10.5487 20 10.5975 19.9909 10.6439 19.9727L10.6539 19.9689L19.7479 16.4102C19.8997 16.3508 19.9996 16.2046 19.9996 16.0415V5.35867V5.35801L20 5.35772ZM10.5 1.82718L18.567 5.33469L16.4424 6.16603L8.34076 2.76603L10.5 1.82718ZM10.5 8.49163L2.43315 5.33482L4.74104 4.33137L12.9725 7.52411L10.5 8.49163ZM7.33631 3.20299L15.3841 6.58046L14.0621 7.09784L5.77298 3.88271L7.33631 3.20299ZM14.4583 7.7927L16.0416 7.17311V10.651L14.4583 11.4427V7.7927ZM1.79199 5.93425L10.1044 9.18678V19.0244L1.79199 15.7715V5.93425ZM19.2083 15.7715L10.8958 19.0243V9.1867L13.6666 8.10259V12.0834C13.6666 12.2206 13.7377 12.348 13.8543 12.42C13.9179 12.4591 13.9903 12.479 14.0625 12.479C14.123 12.479 14.1836 12.4651 14.2396 12.4373L16.6146 11.2498C16.7486 11.1828 16.8333 11.0457 16.8333 10.8957V6.86339L19.2083 5.93417V15.7715Z"
                fill="#313232"
                stroke="#313232"
                strokeWidth="0.4"
            />
        </svg>
    );
}
