export function MailIcon() {
    return (
        <svg width="22" height="15" viewBox="0 0 22 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M2.54882 0.25C1.41216 0.25 0.5 1.16723 0.5 2.26922V12.4731C0.5 13.575 1.41211 14.5 2.54882 14.5H19.4512C20.5878 14.5 21.5 13.575 21.5 12.4731V2.26922C21.5 1.16723 20.5879 0.25 19.4512 0.25H2.54882ZM2.54882 1.2327H19.4512C19.6028 1.2327 19.7459 1.27451 19.8753 1.33244L11.6322 8.84151C11.2538 9.18617 10.7627 9.18617 10.3836 8.84151L2.12454 1.33244C2.25405 1.27451 2.39714 1.2327 2.54863 1.2327H2.54882ZM1.54039 2.15405L7.37436 7.45977L1.63597 12.9572C1.56416 12.8141 1.52396 12.6501 1.52396 12.4736V2.26975C1.52396 2.22982 1.53593 2.19297 1.53986 2.15458L1.54039 2.15405ZM20.4595 2.15405C20.4636 2.19244 20.4754 2.22929 20.4754 2.26922V12.4731C20.4754 12.6472 20.4333 12.8074 20.3634 12.9492L14.6415 7.45174L20.4595 2.15405ZM13.897 8.13499L19.4992 13.5175H19.4511H2.54873H2.50872L8.11906 8.14289L9.67965 9.55559C10.423 10.2315 11.5936 10.2326 12.3367 9.55559L13.897 8.13499Z"
                fill="#313232"
            />
        </svg>
    );
}
