import { ReactNode } from 'react';
import { ButtonVariant, IconPosition, IconProps, getIcon } from './constants';
import { SpinnerIcon } from '../icons';

export function WithIcon({
    position = IconPosition.START,
    variant = ButtonVariant.Primary,
    loading,
    enabled = false,
    children,
    ...iconProps
}: Omit<IconProps, 'position'> & {
    position?: IconPosition;
    enabled?: boolean;
    variant?: ButtonVariant;
    loading?: boolean;
    children: ReactNode;
}) {
    if (!enabled && !loading) return children;

    const iconElement = loading ? (
        <div className={`-my-4 ml-[2rem]`} style={{ marginLeft: -56 }}>
            <SpinnerIcon className=" ml-[2rem]" width={56} height={56} />
        </div>
    ) : (
        getIcon(variant, iconProps)
    );

    return (
        <>
            {position === IconPosition.START && iconElement}
            {children}
            {position === IconPosition.END && iconElement}
        </>
    );
}
