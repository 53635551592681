import { IconProps } from './constants';

export function CartIconOutlined({ className }: IconProps) {
    return (
        <svg
            width="18"
            height="16"
            viewBox="0 0 18 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <path
                d="M17.8908 2.33622C17.7961 2.19873 17.6444 2.12027 17.4739 2.12027H3.84729L3.44923 0.412337C3.39238 0.157082 3.1839 0 2.93748 0H0.530661C0.246432 0 0 0.235539 0 0.54969C0 0.863829 0.246419 1.09938 0.530661 1.09938H2.52067L5.38241 13.3692C5.43926 13.6245 5.64774 13.7816 5.89416 13.7816H6.59535C6.31112 13.9975 6.10263 14.3508 6.10263 14.7632C6.10263 15.4503 6.63329 16 7.29665 16C7.96004 16 8.49067 15.4503 8.49067 14.7632C8.49067 14.3508 8.28219 14.0171 7.99795 13.7816H12.7928C12.5086 13.9975 12.3001 14.3508 12.3001 14.7632C12.3001 15.4503 12.8308 16 13.4941 16C14.1575 16 14.6882 15.4503 14.6882 14.7632C14.6882 14.3508 14.4797 14.0171 14.1954 13.7816H14.5365C14.8207 13.7816 15.0672 13.546 15.0672 13.2319C15.0672 12.9375 14.8208 12.6822 14.5365 12.6822H6.31094L6.00767 11.4062H15.5596C15.806 11.4062 16.0144 11.2295 16.0713 10.9939L17.9854 2.80741C18.0234 2.63076 17.9856 2.45397 17.8908 2.33622L17.8908 2.33622ZM9.11599 7.57791L8.92641 5.94847H11.9966L11.8071 7.57791H9.11599ZM11.6746 8.67739L11.485 10.3068L9.41927 10.3067L9.2297 8.67725L11.6746 8.67739ZM4.73811 5.94857H7.86515L8.05472 7.57801L5.1171 7.57787L4.73811 5.94857ZM8.79387 4.84909L8.6043 3.21965H12.3L12.1104 4.84909H8.79387ZM7.543 3.21976L7.73257 4.8492L4.4728 4.84906L4.09379 3.21962L7.543 3.21976ZM5.36356 8.67739H8.16845L8.35803 10.3068L5.74259 10.3067L5.36356 8.67739ZM12.5464 10.3067L12.736 8.67729H15.522L15.143 10.3067H12.5464ZM15.7873 7.57791H12.8686L13.0582 5.94847H16.1663L15.7873 7.57791ZM16.4127 4.84909H13.1719L13.3614 3.21965H16.7918L16.4127 4.84909Z"
                fill="white"
            />
        </svg>
    );
}
