import { DeIcon } from './DeIcon';
import { EnIcon } from './EnIcon';
import { FiIcon } from './FiIcon';
import { FrIcon } from './FrIcon';
import { NoIcon } from './NoIcon';
import { SvIcon } from './SvIcon';
import { ItIcon } from './ItIcon';

export const flagIconMapping = {
    en: EnIcon,
    sv: SvIcon,
    fi: FiIcon,
    de: DeIcon,
    fr: FrIcon,
    no: NoIcon,
    it: ItIcon,
} as const;
