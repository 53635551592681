export function FooterBgIcon() {
    return (
        <svg width="100%" height="100%" viewBox="0 0 451 499" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g opacity="0.1">
                <mask id="mask0_2266_197" x="0" y="0" width="451" height="499">
                    <rect width="451" height="499" fill="#C4C4C4" />
                </mask>
                <g mask="url(#mask0_2266_197)">
                    <path
                        opacity="0.5"
                        d="M224.146 0L224.146 125.867L336.395 209.66L448.291 125.867L448.291 -3.11721e-05L336.395 83.4361L224.146 0Z"
                        fill="white"
                    />
                    <path
                        opacity="0.5"
                        d="M0 154.004L1.73135e-05 278.498L112.25 361.377L224.146 278.498L224.146 154.004L112.25 236.53L0 154.004Z"
                        fill="white"
                    />
                    <path
                        opacity="0.5"
                        d="M0 450.729L1.73135e-05 575.223L112.25 658.101L224.146 575.223L224.146 450.728L112.25 533.254L0 450.729Z"
                        fill="white"
                    />
                    <path
                        opacity="0.5"
                        d="M224.146 306.17L224.146 432.037L336.395 515.83L448.291 432.037L448.291 306.17L336.395 389.606L224.146 306.17Z"
                        fill="white"
                    />
                </g>
            </g>
        </svg>
    );
}
