export function AccountIcon({ ...props }) {
    return (
        <span {...props}>
            <svg
                className="hidden sm:flex"
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                data-testid="account-icon-customer"
            >
                <path
                    d="M16 18.8024C18.6267 18.8024 20.9675 19.5131 22.6281 20.6203C24.2942 21.7311 25.2 23.1782 25.2 24.6694V27.2029H6.8V24.6694C6.8 23.1782 7.70577 21.7311 9.3719 20.6203C11.0326 19.5131 13.3733 18.8024 16 18.8024Z"
                    stroke="#313232"
                    strokeWidth="1.6"
                />
                <path
                    d="M20.4403 10.0003C20.4403 12.9096 18.2038 15.2006 15.5261 15.2006C12.8483 15.2006 10.6118 12.9096 10.6118 10.0003C10.6118 7.09099 12.8483 4.8 15.5261 4.8C18.2038 4.8 20.4403 7.09099 20.4403 10.0003Z"
                    stroke="#313232"
                    strokeWidth="1.6"
                />
            </svg>
            <svg
                className="sm:hidden flex"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M12 14.452C14.3713 14.452 16.4847 15.0757 17.984 16.0475C19.4899 17.0236 20.3 18.29 20.3 19.5855V21.8022H3.7V19.5855C3.7 18.29 4.51009 17.0236 6.01605 16.0475C7.51526 15.0757 9.62867 14.452 12 14.452Z"
                    stroke="#313232"
                    strokeWidth="1.4"
                />
                <path
                    d="M16.0164 6.75017C16.0164 9.27686 14.0137 11.3003 11.5735 11.3003C9.13336 11.3003 7.13066 9.27686 7.13066 6.75017C7.13066 4.22348 9.13336 2.2 11.5735 2.2C14.0137 2.2 16.0164 4.22348 16.0164 6.75017Z"
                    stroke="#313232"
                    strokeWidth="1.4"
                />
            </svg>
        </span>
    );
}
