export function UspBgIcon({ className }: { className: string }) {
    return (
        <svg className={className} viewBox="0 0 920 270" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <g opacity="0.05">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M-80 0H70.2976L170 135L70.2976 270H-80L18.7103 135L-80 0Z"
                    fill="currentColor"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M170 0H320.298L420 135L320.298 270H170L268.71 135L170 0Z"
                    fill="currentColor"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M420 0H570.298L670 135L570.298 270H420L518.71 135L420 0Z"
                    fill="currentColor"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M670 0H820.298L920 135L820.298 270H670L768.71 135L670 0Z"
                    fill="currentColor"
                />
            </g>
        </svg>
    );
}
