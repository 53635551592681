export function CameraIcon() {
    return (
        <svg width="25" height="19" viewBox="0 0 25 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.54395 0C8.03874 0 7.57886 0.271008 7.31416 0.584571C7.04945 0.898134 6.91473 1.23691 6.79851 1.54276L6.45935 2.43474H2.28608C1.19851 2.43474 0.300781 3.33247 0.300781 4.42003V16.2325C0.300781 17.32 1.19851 18.2178 2.28608 18.2178H22.3155C23.403 18.2178 24.3008 17.32 24.3008 16.2325V4.42003C24.3008 3.33247 23.403 2.43474 22.3155 2.43474H18.1422L17.8031 1.54276C17.6868 1.23689 17.5468 0.898134 17.2819 0.584571C17.0172 0.271019 16.5628 0 16.0577 0H8.54395ZM8.49019 1.41313C8.50299 1.41214 8.51993 1.41313 8.54395 1.41313H16.0576C16.1537 1.41313 16.1291 1.40761 16.2037 1.49585C16.2784 1.58428 16.3877 1.78694 16.485 2.04316L16.9951 3.38736C17.0977 3.66211 17.3594 3.84488 17.6527 3.84646H22.3154C22.6453 3.84646 22.8889 4.09009 22.8889 4.41999V16.2324C22.8889 16.5623 22.6453 16.8046 22.3154 16.8046H2.28598C1.95608 16.8046 1.71245 16.5623 1.71245 16.2324V4.41999C1.71245 4.09009 1.95608 3.84646 2.28598 3.84646H6.94455C7.23939 3.84666 7.50331 3.66349 7.60632 3.38736L8.11642 2.04316C8.21371 1.78692 8.31751 1.58425 8.39215 1.49585C8.44809 1.42947 8.45144 1.41588 8.49004 1.41313H8.49019ZM12.3008 4.76884C9.23918 4.76884 6.74051 7.26741 6.74051 10.3292C6.74051 13.3908 9.23908 15.8825 12.3008 15.8825C15.3626 15.8825 17.8612 13.3908 17.8612 10.3292C17.8612 7.26751 15.3626 4.76884 12.3008 4.76884ZM20.8612 5.21553C20.6471 5.21652 20.4421 5.30239 20.2914 5.45444C20.1407 5.60648 20.0564 5.81211 20.0574 6.0262C20.058 6.23931 20.1427 6.44355 20.2934 6.59444C20.4438 6.74531 20.6481 6.83039 20.8612 6.83137C21.0755 6.83236 21.2815 6.74787 21.4336 6.5968C21.5856 6.44594 21.6713 6.24051 21.6719 6.02625C21.6729 5.81098 21.5876 5.60418 21.4355 5.45191C21.2833 5.29987 21.0765 5.21458 20.8612 5.21557L20.8612 5.21553ZM12.3008 6.18062C14.5995 6.18062 16.4493 8.03044 16.4493 10.329C16.4493 12.6277 14.5994 14.4706 12.3008 14.4706C10.0022 14.4706 8.15378 12.6277 8.15378 10.329C8.15378 8.03039 10.0022 6.18062 12.3008 6.18062Z"
                fill={'currentColor'}
            />
        </svg>
    );
}
