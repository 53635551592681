export function SearchIcon({
    className,
    stroke,
    iconSize = '',
}: {
    className?: string;
    stroke?: string;
    iconSize?: string;
}) {
    switch (iconSize) {
        case 'large':
            return (
                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M24.6333 13.5605C24.6333 18.9853 20.1792 23.3976 14.6667 23.3976C9.15414 23.3976 4.7 18.9853 4.7 13.5605C4.7 8.13568 9.15414 3.72344 14.6667 3.72344C20.1792 3.72344 24.6333 8.13568 24.6333 13.5605Z"
                        stroke="#313232"
                        strokeWidth="1.4"
                    />
                    <line x1="22.0391" y1="20.8788" x2="28.7058" y2="27.5455" stroke="#313232" strokeWidth="2" />
                </svg>
            );
        case 'small':
            return (
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M18.3 10.1684C18.3 14.1384 15.0398 17.3712 11 17.3712C6.96023 17.3712 3.7 14.1384 3.7 10.1684C3.7 6.19847 6.96023 2.96562 11 2.96562C15.0398 2.96562 18.3 6.19847 18.3 10.1684Z"
                        stroke="#313232"
                        strokeWidth="1.4"
                    />
                    <line x1="16.7071" y1="15.4804" x2="21.7071" y2="20.4804" stroke="#313232" strokeWidth="2" />
                </svg>
            );
        default:
            return (
                <svg
                    className={className}
                    stroke={stroke}
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <circle cx="10.6612" cy="10.2276" r="8.72765" strokeWidth="3" />
                    <line x1="17.4884" y1="17.5985" x2="22.829" y2="22.9391" strokeWidth="3" />
                </svg>
            );
    }
}
