export function CloseIcon({ className = '' }: { className?: string }) {
    return (
        <svg className={className} viewBox="0 0 20 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M17.143 20L20 17.143L12.857 10L20 2.857L17.143 -1.24883e-07L10 7.143L2.857 -7.49344e-07L-1.24883e-07 2.857L7.143 10L-7.49344e-07 17.143L2.857 20L10 12.857L17.143 20Z"
                fill="currentColor"
            />
        </svg>
    );
}
