export function LogoIcon({ className }: { className: string }) {
    return (
        <svg viewBox="0 0 136 32" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
            <g clipPath="url(#clip0_239_5076)">
                <path
                    d="M27.9702 7.54185L20.978 11.1842L13.9844 7.54185V0L20.978 3.64368L27.9702 0V7.54185Z"
                    fill="#857350"
                />
                <path
                    d="M13.9858 16.4012L6.99365 20.0436L0 16.4012V8.85938L6.99365 12.5031L13.9858 8.85938V16.4012Z"
                    fill="#857350"
                />
                <path
                    d="M27.9702 25.2684L20.978 28.9121L13.9844 25.2684V17.7266L20.978 21.3702L27.9702 17.7266V25.2684Z"
                    fill="#857350"
                />
                <path
                    d="M49.4425 20.6927H50.0346L51.7266 1.35938H57.9841L54.6015 26.7978H44.7068L41.5352 1.36072H47.7926L49.4425 20.6927Z"
                    fill="#857350"
                />
                <path
                    d="M59.082 1.35938H71.5984V6.64311H65.2987V11.496H69.8234V16.7407H65.2987V21.5545H71.5984V26.7965H59.082V1.35938Z"
                    fill="#857350"
                />
                <path d="M73.1211 1.35938H79.3364V21.5531H85.6797V26.7965H73.1211V1.35938Z" fill="#857350" />
                <path
                    d="M87.0781 26.7965V1.35938H98.3259C100.99 1.35938 101.962 2.72878 101.962 6.21223V14.2348C101.962 16.8174 101.412 18.2663 100.016 18.8129L102.301 26.7965H96.0853L94.0558 19.4781H93.2949V26.7965H87.0781ZM95.8729 14.2348V6.64177H93.2934V14.2334H95.8729V14.2348Z"
                    fill="#857350"
                />
                <path
                    d="M103.695 21.5531V6.87741C103.695 3.47206 105.641 1.35938 108.6 1.35938H113.717C117.141 1.35938 118.876 3.47341 118.876 6.91646V21.5531C118.876 24.879 117.141 26.7965 113.887 26.7965H108.643C105.64 26.7965 103.695 24.9962 103.695 21.5531ZM112.659 21.5531V6.64177H109.911V21.5531H112.659Z"
                    fill="#857350"
                />
                <path
                    d="M120.82 21.5531V6.87741C120.82 3.47206 122.766 1.35938 125.725 1.35938H130.842C134.266 1.35938 136.001 3.47341 136.001 6.91646V21.5531C136.001 24.4872 134.648 26.2888 132.152 26.7184L133.168 32.0021H127.037L126.19 26.7965H125.767C122.766 26.7965 120.82 24.9962 120.82 21.5531ZM129.784 21.5531V6.64177H127.036V21.5531H129.784Z"
                    fill="#857350"
                />
            </g>
            <defs>
                <clipPath id="clip0_239_5076">
                    <rect width="136" height="32" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}
