export function DeleteIcon({ className }: { className?: string }) {
    return (
        <svg className={className} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M15.4688 17.069C15.4688 17.3927 15.2205 17.6563 14.9153 17.6563H5.0847C4.77953 17.6563 4.53124 17.3927 4.53124 17.069V4.53124H15.4687L15.4688 17.069ZM6.51004 2.34375H13.4903L14.0372 3.43749H5.96317L6.51004 2.34375ZM18.2031 3.43749C18.505 3.43749 18.75 3.68248 18.75 3.98436C18.75 4.28624 18.505 4.53124 18.2031 4.53124H16.5625V17.069C16.5625 17.9965 15.8231 18.75 14.9153 18.75H5.0847C4.17577 18.75 3.43749 17.9965 3.43749 17.069V4.53124H1.79687C1.49499 4.53124 1.25 4.28624 1.25 3.98436C1.25 3.68248 1.49499 3.43749 1.79687 3.43749H4.74011L5.68298 1.55188L5.68738 1.55517C5.77807 1.37585 5.95862 1.25 6.17188 1.25H13.8281C14.0414 1.25 14.2219 1.37585 14.3126 1.55517L14.317 1.55188L15.2599 3.43749H18.2031ZM10 6.71872C9.69815 6.71872 9.45316 6.96372 9.45316 7.2656V14.9218C9.45316 15.2237 9.69815 15.4687 10 15.4687C10.3019 15.4687 10.5469 15.2237 10.5469 14.9218V7.2656C10.5469 6.96372 10.3019 6.71872 10 6.71872ZM7.26567 6.71872C6.96379 6.71872 6.71879 6.96372 6.71879 7.2656V14.9218C6.71879 15.2237 6.96379 15.4687 7.26567 15.4687C7.56755 15.4687 7.81254 15.2237 7.81254 14.9218V7.2656C7.81254 6.96372 7.56755 6.71872 7.26567 6.71872ZM12.7344 6.71872C12.4325 6.71872 12.1875 6.96372 12.1875 7.2656V14.9218C12.1875 15.2237 12.4325 15.4687 12.7344 15.4687C13.0363 15.4687 13.2813 15.2237 13.2813 14.9218V7.2656C13.2813 6.96372 13.0363 6.71872 12.7344 6.71872Z"
                fill="currentColor"
            />
        </svg>
    );
}
