import { MixpanelTrackProps } from '@lib/machine-parts/storefront/utils';

import { ButtonIcon } from '../icons';
import { IconProps as IconComponentProps } from '../icons/constants';

export enum ButtonVariant {
    None = 'none',
    Primary = 'primary',
    Secondary = 'secondary',
    Tertiary = 'tertiary',
    Menu = 'menu',
}

export interface CommonButtonProps {
    variant?: ButtonVariant;
    mixpanel?: MixpanelTrackProps;
    icon?: true | IconProps;
    selected?: boolean;
    border?: boolean;
}

const DISABLED_CLASSES = 'disabled:cursor-not-allowed disabled:opacity-50';
const DEFAULT_CLASSES =
    'm-0 w-auto flex gap-2 space-x-2 items-center justify-center text-center text-sm lg:text-base uppercase px-6 py-4';
const SELECTED_CLASSES = 'font-bold pointer-events-none';
const BORDERED_CLASSES = 'border-2 border-brand-brown';

const VARIANT_CLASSES: Record<ButtonVariant, string> = {
    [ButtonVariant.Primary]: 'bg-brand-brown text-white font-semibold',
    [ButtonVariant.Secondary]: 'text-brand-brown bg-transparent font-semibold',
    [ButtonVariant.Tertiary]:
        'text-brand-black bg-transparent px-0 py-0 font-normal !text-sm normal-case underline underline-offset-4 font-semibold',
    [ButtonVariant.Menu]: 'text-black capitalize py-2',
    [ButtonVariant.None]: '',
};

export function getButtonVariantClasses({
    variant,
    selected,
    border,
}: {
    variant: ButtonVariant;
    selected?: boolean;
    border?: boolean;
}) {
    return `${DISABLED_CLASSES} ${variant === ButtonVariant.None ? '' : DEFAULT_CLASSES} ${VARIANT_CLASSES[variant]} ${
        selected ? SELECTED_CLASSES : ''
    } ${border ? BORDERED_CLASSES : ''}`;
}

export enum IconPosition {
    START = 'start',
    END = 'end',
}

export type IconProps = {
    position?: IconPosition;
    className?: string;
} & IconComponentProps;

export function getIcon(variant: ButtonVariant, { className, ...iconProps }: Omit<IconProps, 'position'>) {
    return (
        <ButtonIcon
            {...iconProps}
            className={`${variant === ButtonVariant.Primary ? 'fill-white' : 'fill-brand-brown'} ${className}`}
        />
    );
}
