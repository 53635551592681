export function LockIcon() {
    return (
        <svg
            width="16"
            height="22"
            viewBox="0 0 16 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="h-full"
        >
            <path
                d="M7.04578 14.4631V16.7765C7.04578 16.9984 7.22514 17.1778 7.44701 17.1778H8.3018C8.52367 17.1778 8.70303 16.9984 8.70303 16.7765V14.4631C9.19607 14.176 9.53101 13.6478 9.53101 13.0365C9.53101 12.0047 8.58778 11.1925 7.51521 11.4176C6.91443 11.5436 6.41407 12.0248 6.26777 12.6213C6.0768 13.4034 6.44284 14.1143 7.04577 14.4642L7.04578 14.4631Z"
                fill="#313232"
                stroke="white"
                strokeWidth="0.2"
            />
            <path
                d="M5.76343 21.4578H9.98496C12.7796 21.4578 15.0535 19.1838 15.0535 16.3893V8.96641C15.0535 7.9367 14.2159 7.09906 13.1861 7.09906H12.5886V5.21456C12.5886 2.61408 10.4737 0.5 7.87403 0.5C5.27442 0.5 3.15947 2.61495 3.15947 5.21456V7.09906H2.56193C1.53222 7.09906 0.69458 7.9367 0.69458 8.96641V16.3893C0.69458 19.1839 2.96854 21.4578 5.76311 21.4578H5.76343ZM4.76034 5.21452C4.76034 3.49758 6.15715 2.10077 7.87408 2.10077C9.59102 2.10077 10.9878 3.49758 10.9878 5.21452V7.09902H4.75906V5.21452H4.76034ZM2.29545 8.96637C2.29545 8.81916 2.41496 8.69965 2.56216 8.69965H13.1863C13.3335 8.69965 13.453 8.81917 13.453 8.96637V16.3892C13.453 18.3014 11.8972 19.8572 9.985 19.8572H5.76348C3.85131 19.8572 2.29549 18.3014 2.29549 16.3892L2.29545 8.96637Z"
                fill="#313232"
                stroke="white"
                strokeWidth="0.2"
            />
        </svg>
    );
}
