export function SelectIcon({ className }: { className?: string }) {
    return (
        <svg
            width="7"
            height="6"
            className={className}
            viewBox="0 0 7 6"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M7 0.5L7 2.64529L3.49447 5.5L-9.84362e-08 2.64529L0 0.5L3.49447 3.34257L7 0.5Z" />
        </svg>
    );
}
