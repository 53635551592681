import { ICON_DEFAULT_CLASSES } from './constants';

export function VisaIcon() {
    return (
        <div className={ICON_DEFAULT_CLASSES}>
            <svg
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                x="0"
                y="0"
                viewBox="0 0 38 16"
                xmlSpace="preserve"
                width="38"
                height="24"
                role="img"
                aria-labelledby="pi-uaevisa"
            >
                <title id="pi-uaevisa">UAE Visa Credit Card</title>
                <path
                    d="M27.5 5h.8c.2 0 .2 0 .2.2.3 1.6.7 3.2 1 4.7.2.8.3 1.7.5 2.5 0 .1 0 .1-.1.1h-1.6c-.1 0-.1 0-.2-.1-.1-.4-.1-.6-.2-.9 0-.1-.1-.1-.1-.1h-2.2c-.1 0-.1 0-.2.1l-.3.9c0 .1-.1.1-.2.1h-1.8c-.1 0-.1 0-.1-.1.4-.8.7-1.7 1.1-2.5.6-1.4 1.2-2.8 1.7-4.2 0-.1.1-.2.2-.3.1-.3.4-.4.6-.4h.9zM27 7.1c-.1.1-.1.1-.1.2L26 9.7c0 .1 0 .1.1.1h1.4c.1 0 .1 0 .1-.1-.1-.7-.3-1.3-.4-2-.1-.2-.1-.4-.2-.6zm-7.2 5.5c-.7 0-1.4-.1-2.1-.3-.2-.1-.2-.1-.1-.2.1-.5.2-.9.2-1.4 0-.1 0-.1.1-.1.6.3 1.2.5 1.9.4.2 0 .4 0 .6-.1.2 0 .3-.1.4-.3.2-.3.2-.5 0-.8-.2-.2-.4-.3-.5-.4-.3-.2-.7-.4-1-.6-.3-.2-.6-.5-.7-.8-.3-.6-.3-1.2 0-1.8s.7-.9 1.3-1.2c.7-.3 1.4-.4 2.1-.3.4 0 .8.1 1.2.2.1 0 .1.1.1.2-.1.5-.1.9-.2 1.4 0 .1 0 .1-.2.1-.5-.2-1.1-.3-1.6-.3-.2 0-.3 0-.5.1s-.3.2-.4.4c-.1.2-.1.3.1.5s.5.4.8.5.6.3.8.4c1.2.8 1.2 2.1.6 3-.4.5-.9.9-1.5 1.1-.4.2-.9.3-1.4.3zm-9.1-3.5c.1.3.1.6.2.9l.1-.1c.6-1.6 1.2-3.2 1.8-4.9 0-.1.1-.1.2-.1h1.8c.1 0 .1 0 .1.1-1 2.4-1.9 4.8-2.9 7.2-.1.2-.1.2-.3.2H10c-.1 0-.2 0-.2-.1-.6-2.1-1.1-4.2-1.7-6.3v-.1h.1c.8.5 1.5 1.1 2 2 .2.4.4.8.5 1.2zm4.7 3.4h-.8c-.1 0-.1 0-.1-.1.2-1 .3-2 .5-3 .2-1.4.4-2.8.7-4.2 0-.1 0-.1.2-.1h1.7c.1 0 .1 0 .1.1l-1.2 7.2c0 .1 0 .2-.2.2-.3-.1-.6-.1-.9-.1z"
                    fill="#1e4ea2"
                />
                <path
                    d="M10.7 9.1c-.2-.4-.3-.8-.5-1.1-.5-.8-1.2-1.5-2-2h-.1c-.4-.3-.8-.4-1.1-.6-.3-.1-.7-.2-1-.3h-.1.1c.1-.1.2-.1.3-.1h3.1c.3 0 .6.2.6.6.2.9.3 1.7.5 2.6.1.2.2.5.2.9 0-.1 0-.1 0 0z"
                    fill="#f1a92e"
                />
            </svg>
        </div>
    );
}
