export function BankDepositIcon() {
    return (
        <svg width="43" height="28" viewBox="0 0 43 28" fill="none" xmlns="http://www.w3.org/2000/svg" role="img">
            <rect x="0.355263" y="0.872841" width="41.9211" height="26.2895" rx="2.48684" fill="#0009E3" />
            <path
                d="M21.177 18.6071C18.5424 18.6071 18.0308 16.9701 17.9029 14.9493H16.9437V13.8366H17.9029C18.0692 11.8543 18.5935 10.2428 21.177 10.2428C23.6454 10.2428 24.3744 11.7519 24.3744 13.4529H23.0187C22.9292 12.3019 22.635 11.4066 21.177 11.4066C19.5783 11.4066 19.3737 12.4426 19.2842 13.8366H21.9316V14.9493H19.2586C19.3609 16.3817 19.5527 17.4305 21.177 17.4305C22.6478 17.4305 22.9292 16.5864 23.0187 15.3714H24.3744C24.3744 17.1747 23.6582 18.6071 21.177 18.6071Z"
                fill="white"
            />
            <rect
                x="0.355263"
                y="0.872841"
                width="41.9211"
                height="26.2895"
                rx="2.48684"
                stroke="#CCCCCC"
                strokeWidth="0.710526"
            />
        </svg>
    );
}
