import { IconProps } from './constants';

export function ButtonIcon({ className = '', size = 1 }: IconProps) {
    return (
        <svg
            className={className}
            width={`${size * 14}`}
            height={`${size * 16}`}
            viewBox="0 0 14 16"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M0 0H8.40476L14 8.01264L8.40476 16H0L5.57143 8.01264L0 0Z" />
        </svg>
    );
}
