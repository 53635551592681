export * from './locale';
export * from './paymentOptions';

export * from './AccountIcon';
export * from './ButtonIcon';
export * from './CameraIcon';
export * from './CartIcon';
export * from './CartIconOutlined';
export * from './ChevronDownIcon';
export * from './CloseIcon';
export * from './DeleteIcon';
export * from './EditIcon';
export * from './FooterBgIcon';
export * from './HeadsetIcon';
export * from './LockIcon';
export * from './LogoIcon';
export * from './MailIcon';
export * from './ParcelIcon';
export * from './SearchIcon';
export * from './SelectIcon';
export * from './SpinnerIcon';
export * from './UspBgIcon';
