import { SVGProps } from 'react';

export function ItIcon(props: SVGProps<SVGSVGElement>) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
            style={{
                width: '28px',
                height: '28px',
            }}
            xmlSpace="preserve"
            {...props}
        >
            <path
                style={{
                    fill: '#008C45',
                }}
                d="M0 88.276h170.667v335.448H0z"
            />
            <path
                style={{
                    fill: '#ffffff',
                }}
                d="M170.667 88.276h170.667v335.448H170.667z"
            />
            <path
                style={{
                    fill: '#CD212A',
                }}
                d="M341.333 88.276H512v335.448H341.333z"
            />
        </svg>
    );
}
