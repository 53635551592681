export function CartIcon({ cartIconSize = 'large' }: { cartIconSize?: string }) {
    return cartIconSize === 'large' ? (
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M28.9307 10.7275L28.9298 10.7262C28.8938 10.6746 28.8451 10.6316 28.7874 10.6015C28.7297 10.5715 28.665 10.5556 28.599 10.5556H9.466H8.80134L8.544 9.94274L6.92844 6.09552L6.92784 6.09409C6.79287 5.77129 6.56379 5.49423 6.26838 5.29896C5.97289 5.10363 5.62476 4.99926 5.26819 5L5.26611 5.00001L3 5.00001V5.77778H5.26611H5.93073L6.18809 6.39054L12.8294 22.2027C12.8588 22.2726 12.9089 22.3337 12.9748 22.3772C13.0407 22.4207 13.1189 22.4445 13.1996 22.4445H13.1997H24.3994C24.5704 22.4445 24.7188 22.3391 24.7763 22.1895C24.7764 22.1892 24.7765 22.1888 24.7767 22.1885L28.9755 11.0801L28.9307 10.7275ZM28.9307 10.7275C28.9668 10.779 28.9894 10.8379 28.997 10.899C29.0047 10.9599 28.9974 11.0219 28.9757 11.0798L28.9307 10.7275Z"
                stroke="currentColor"
                strokeWidth="2"
            />
            <path
                d="M13.8968 29.0026C15.0566 29.0026 15.9968 28.0699 15.9968 26.9193C15.9968 25.7687 15.0566 24.8359 13.8968 24.8359C12.7371 24.8359 11.7969 25.7687 11.7969 26.9193C11.7969 28.0699 12.7371 29.0026 13.8968 29.0026Z"
                fill="currentColor"
            />
            <path
                d="M23.6976 29.0026C24.8574 29.0026 25.7975 28.0699 25.7975 26.9193C25.7975 25.7687 24.8574 24.8359 23.6976 24.8359C22.5378 24.8359 21.5977 25.7687 21.5977 26.9193C21.5977 28.0699 22.5378 29.0026 23.6976 29.0026Z"
                fill="currentColor"
            />
        </svg>
    ) : (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M21.739 8.01695L21.7384 8.01607C21.7067 7.97068 21.6641 7.93302 21.6137 7.9068C21.5633 7.8806 21.5069 7.86673 21.4495 7.86667L21.739 8.01695ZM21.739 8.01695C21.7707 8.06226 21.7906 8.11411 21.7974 8.16799C21.8042 8.22177 21.7977 8.27645 21.7785 8.3275L21.739 8.01695ZM6.63424 7.86667L6.4541 7.4377L5.24243 4.55228L5.24201 4.55128C5.13694 4.29999 4.95865 4.0844 4.72886 3.93251C4.49901 3.78057 4.22828 3.69943 3.95104 3.7L3.94958 3.70001L2.2 3.7V4.38334H3.94958H4.41481L4.59497 4.81227L9.57593 16.6714C9.60184 16.733 9.64593 16.7865 9.7035 16.8246C9.76113 16.8627 9.82942 16.8833 9.89972 16.8833H9.89978H18.2996C18.4485 16.8833 18.5786 16.7915 18.629 16.6597C18.6291 16.6595 18.6292 16.6593 18.6292 16.6592L21.7784 8.32777L6.63424 7.86667ZM6.63424 7.86667H7.0995M6.63424 7.86667H7.0995M7.0995 7.86667H21.4492H7.0995Z"
                stroke="currentColor"
                strokeWidth="1.4"
            />
            <path
                d="M10.4226 21.752C11.2924 21.752 11.9976 21.0524 11.9976 20.1895C11.9976 19.3265 11.2924 18.627 10.4226 18.627C9.55279 18.627 8.84766 19.3265 8.84766 20.1895C8.84766 21.0524 9.55279 21.752 10.4226 21.752Z"
                fill="currentColor"
            />
            <path
                d="M17.7732 21.752C18.643 21.752 19.3482 21.0524 19.3482 20.1895C19.3482 19.3265 18.643 18.627 17.7732 18.627C16.9034 18.627 16.1982 19.3265 16.1982 20.1895C16.1982 21.0524 16.9034 21.752 17.7732 21.752Z"
                fill="currentColor"
            />
        </svg>
    );
}
