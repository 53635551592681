import { ICON_DEFAULT_CLASSES } from './constants';

export function MasterCardIcon() {
    return (
        <div className={ICON_DEFAULT_CLASSES}>
            <svg
                viewBox="0 0 38 22"
                xmlns="http://www.w3.org/2000/svg"
                role="img"
                width="38"
                height="24"
                aria-labelledby="pi-master"
            >
                <title id="pi-master">Mastercard</title>
                <circle fill="#EB001B" cx="15" cy="12" r="7" />
                <circle fill="#F79E1B" cx="23" cy="12" r="7" />
                <path
                    fill="#FF5F00"
                    d="M22 12c0-2.4-1.2-4.5-3-5.7-1.8 1.3-3 3.4-3 5.7s1.2 4.5 3 5.7c1.8-1.2 3-3.3 3-5.7z"
                />
            </svg>
        </div>
    );
}
