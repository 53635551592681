'use client';

import NextLink from 'next/link';
import { usePathname, useSearchParams } from 'next/navigation';
import { ComponentProps } from 'react';

import { internalDeconstructPathname, MixpanelTrack, omittedParams } from '@lib/machine-parts/storefront/utils';

import { ButtonVariant, CommonButtonProps, getButtonVariantClasses } from '../../atoms/constants';
import { WithIcon } from '../../atoms/withIcon';

type LinkProps = {
    openInNewTab?: boolean;
    disabled?: boolean;
} & CommonButtonProps &
    ComponentProps<typeof NextLink>;

export function Link({
    variant = ButtonVariant.Primary,
    mixpanel,
    children,
    openInNewTab,
    icon,
    border,
    disabled,
    href,
    selected,
    ...buttonProps
}: LinkProps) {
    const searchParams = useSearchParams();
    const pathname = usePathname() ?? '';
    const deconstructedPath = internalDeconstructPathname<string | undefined, string | undefined>({
        path: pathname,
        defaultCountry: undefined,
        defaultLanguage: undefined,
    });

    buttonProps.className = `${getButtonVariantClasses({ variant, border })} ${buttonProps.className} ${
        disabled ? 'pointer-events-none' : ''
    } ${selected ? 'font-bold' : ''}`;

    const _href: string =
        href?.toString().startsWith('http') || href?.toString().startsWith('mailto:')
            ? href.toString()
            : getUrl({
                  language: deconstructedPath.language,
                  locale: deconstructedPath.locale,
                  url: href.toString(),
                  searchParams: searchParams
                      ? new URLSearchParams(Array.from(searchParams.entries()))
                      : new URLSearchParams(),
              });

    return (
        <MixpanelTrack {...mixpanel}>
            <NextLink
                {...(openInNewTab && { target: '_blank', rel: 'noreferrer' })}
                aria-disabled={disabled}
                {...buttonProps}
                href={_href}
            >
                <WithIcon enabled={Boolean(icon)} variant={variant} {...(icon === true ? {} : icon)}>
                    {children}
                </WithIcon>
            </NextLink>
        </MixpanelTrack>
    );
}

function getUrl({
    url,
    searchParams,
    language,
    locale,
}: {
    url: string;
    searchParams: URLSearchParams;
    language?: string;
    locale?: string;
}): string {
    if (!url || !searchParams) return url;
    const [base, params] = url.split('?');

    for (const k of Object.keys(omittedParams)) {
        searchParams.delete(k);
    }

    for (const pair of params?.split('&') ?? []) {
        const split = pair.split('=');
        if (split.length > 1) {
            const [k, v] = split;
            searchParams.set(k, v);
        }
    }

    const i18nPrefix = language && locale ? `/${language}/${locale}` : '';
    return `${i18nPrefix}${base}${[...searchParams.values()].length ? '?' : ''}${searchParams.toString()}`;
}
